import React from "react"
import "./contractor-benefits.scss"

function OncologyBenefitsDiagram() {
  return (
    <svg
      width="70%"
      height="auto"
      viewBox="0 0 1396 904"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id="benefits-diagram"
    >
      <path id="Path_4047" class="st0" d="M456.2,212.5c0,0,293,255.7,0,488" />
      <path
        id="Path_4047 copy"
        fill-rule="evenodd"
        class="st0"
        d="m638.2 485.4c0 0 8.6 388.8-360.5 329"
      />
      <path id="Path_4047" class="st0" d="M456.2,212.5c0,0,293,255.7,0,488" />
      <path
        id="Path_4048"
        class="st0"
        d="M339.1,372.7c0,0,197,49,197,91.6s-197,72.5-197,72.5"
      />
      <path id="Path_4050" class="st0" d="M953,180.5c0,0-352.4,307.6,0,587" />
      <path
        id="Path_4051"
        class="st0"
        d="M1039.7,281.5c0,0-197,97.7-197,182.4s197,144.5,197,144.5"
      />
      <g>
        <g>
          <g>
            <g>
              <g>
                <defs>
                  <rect id="SVGID_1_" x="747" y="45" width="180" height="412" />
                </defs>
                <clipPath id="SVGID_2_">
                  <use xlinkHref="#SVGID_1_" style={{ overflow: "visible" }} />
                </clipPath>
                <g class="st1">
                  <g id="Mask_Group_25">
                    <path
                      id="Path_4049"
                      class="st0"
                      d="M828,45c0,0-153.2,412.4,0,817.6"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <g>
              <g>
                <defs>
                  <rect id="SVGID_3_" x="483" y="19" width="249" height="460" />
                </defs>
                <clipPath id="SVGID_4_">
                  <use xlinkHref="#SVGID_3_" style={{ overflow: "visible" }} />
                </clipPath>
                <g class="st2">
                  <g id="Mask_Group_26">
                    <path
                      id="Path_4046"
                      class="st0"
                      d="M568,45c0,0,153.2,412.4,0,817.6"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <path id="Path_4052" className="st0" d="M698,503v329.9" />
      <line className="st0" x1="842.7" y1="464" x2="1039.7" y2="464" />
      <g id="Component_75_7">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <defs>
                    <rect
                      id="SVGID_5_"
                      x="527"
                      y="812"
                      width="342"
                      height="92"
                    />
                  </defs>
                  <clipPath id="SVGID_6_">
                    <use
                      xlinkHref="#SVGID_5_"
                      style={{ overflow: "visible" }}
                    />
                  </clipPath>
                  <g class="st3">
                    <g id="Mask_Group_22">
                      <g id="Group_25861">
                        <g id="Group_25859">
                          <path
                            id="Rectangle_300"
                            class="st4"
                            d="M542,812h312c8.3,0,15,6.7,15,15v62c0,8.3-6.7,15-15,15H542c-8.3,0-15-6.7-15-15
										v-62C527,818.7,533.7,812,542,812z"
                          />
                          <g id="Group_25804">
                            <g id="Flexible_Spending">
                              <text
                                transform="matrix(1 0 0 1 627.07 855)"
                                class="st5 st6 st7"
                              >
                                FLEXIBLE
                              </text>
                              <text
                                transform="matrix(1 0 0 1 620.6 885)"
                                class="st5 st6 st7"
                              >
                                SPENDING
                              </text>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Component_75_9">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <defs>
                    <rect
                      id="SVGID_7_"
                      x="114"
                      y="650"
                      width="342"
                      height="92"
                    />
                  </defs>
                  <clipPath id="SVGID_8_">
                    <use
                      xlinkHref="#SVGID_7_"
                      style={{ overflow: "visible" }}
                    />
                  </clipPath>
                  <g class="st8">
                    <g id="Mask_Group_22-2">
                      <g id="Group_25861-2">
                        <g id="_2_plans_through_MetLife-2">
                          <text
                            transform="matrix(1 0 0 1 201 780)"
                            class="st9 st10 st11"
                          >
                            2 plans through MetLife
                          </text>
                        </g>
                        <g id="Group_25859-2">
                          <path
                            id="Rectangle_300-2"
                            class="st4"
                            d="M129,650h312c8.3,0,15,6.7,15,15v62c0,8.3-6.7,15-15,15H129c-8.3,0-15-6.7-15-15
										v-62C114,656.7,120.7,650,129,650z"
                          />
                          <g id="Group_25804-2">
                            <g id="Accident">
                              <text
                                transform="matrix(1 0 0 1 210 708)"
                                class="st5 st6 st7"
                              >
                                ACCIDENT
                              </text>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Group_25859-2">
        <path
          id="Rectangle_300-2"
          class="st4"
          d="m168 783h312c8.3 0 15 6.7 15 15v62c0 8.3-6.7 15-15 15h-312c-8.3 0-15-6.7-15-15v-62c0-8.3 6.7-15 15-15z"
        />
        <g id="Group_25804-2" class="st4">
          <g id="Accident" class="st4">
            <text
              id="PAID SICK LEAVE"
              style={{ transform: "matrix(1, 0, 0, 1, 195, 840)" }}
              class="st4"
            >
              <tspan x="0" y="0" class="st5 st7">
                PAID SICK LEAVE
              </tspan>
            </text>
          </g>
        </g>
      </g>
      <g id="Component_76_12">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <defs>
                    <rect
                      id="SVGID_9_"
                      x="939"
                      y="743.5"
                      width="342"
                      height="92"
                    />
                  </defs>
                  <clipPath id="SVGID_10_">
                    <use
                      xlinkHref="#SVGID_9_"
                      style={{ overflow: "visible" }}
                    />
                  </clipPath>
                  <g class="st12">
                    <g id="Mask_Group_24">
                      <g id="Group_25863">
                        <g id="_-_Automatic_10_000_coverage_paid_by_PeerSource_-_Option_to_elect_supplemental_coverage_through_Unum_">
                          <text
                            transform="matrix(1 0 0 1 985 863.5)"
                            class="st9 st10 st11"
                          >
                            -
                          </text>
                          <text
                            transform="matrix(1 0 0 1 990.33 863.5)"
                            class="st9 st10 st11"
                          >
                            {" "}
                          </text>
                          <text
                            transform="matrix(1 0 0 1 993.89 863.5)"
                            class="st9 st10 st11"
                          >
                            Automatic $10,000 coverage paid
                          </text>
                          <text
                            transform="matrix(1 0 0 1 985 882.5)"
                            class="st9 st10 st11"
                          >
                            by PeerSource
                          </text>
                        </g>
                        <g id="Group_25862">
                          <path
                            id="Rectangle_300-3"
                            class="st4"
                            d="M954,743.5h312c8.3,0,15,6.7,15,15v62c0,8.3-6.7,15-15,15H954
										c-8.3,0-15-6.7-15-15v-62C939,750.2,945.7,743.5,954,743.5z"
                          />
                          <g id="Group_25804-3">
                            <g id="Benefit_Contribution">
                              <text
                                transform="matrix(1 0 0 1 1049.04 780.5)"
                                class="st5 st6 st7"
                              >
                                BENEFIT
                              </text>
                              <text
                                transform="matrix(1 0 0 1 993.55 816.5)"
                                class="st5 st6 st7"
                              >
                                CONTRIBUTION
                              </text>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Component_76_13">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <defs>
                    <rect
                      id="SVGID_11_"
                      x="1000.1"
                      y="575.8"
                      width="342"
                      height="92"
                    />
                  </defs>
                  <clipPath id="SVGID_12_">
                    <use
                      xlinkHref="#SVGID_11_"
                      style={{ overflow: "visible" }}
                    />
                  </clipPath>
                  <g class="st13">
                    <g id="Mask_Group_24-2">
                      <g id="Group_25863-2">
                        <g id="_-_Automatic_10_000_coverage_paid_by_PeerSource_-_Option_to_elect_supplemental_coverage_through_Unum_2">
                          <text
                            transform="matrix(1 0 0 1 1046.14 695.75)"
                            class="st9 st10 st11"
                          >
                            -
                          </text>
                          <text
                            transform="matrix(1 0 0 1 1051.47 695.75)"
                            class="st9 st10 st11"
                          >
                            {" "}
                          </text>
                          <text
                            transform="matrix(1 0 0 1 1055.03 695.75)"
                            class="st9 st10 st11"
                          >
                            Automatic $10,000 coverage paid
                          </text>
                          <text
                            transform="matrix(1 0 0 1 1046.14 714.75)"
                            class="st9 st10 st11"
                          >
                            by PeerSource
                          </text>
                          <text
                            transform="matrix(1 0 0 1 1046.14 752.75)"
                            class="st9 st10 st11"
                          >
                            - Option to elect supplemental
                          </text>
                          <text
                            transform="matrix(1 0 0 1 1046.14 771.75)"
                            class="st9 st10 st11"
                          >
                            coverage through Unum
                          </text>
                        </g>
                        <g id="Group_25862-2">
                          <path
                            id="Rectangle_300-4"
                            class="st4"
                            d="M1015.1,575.8h312c8.3,0,15,6.7,15,15v62c0,8.3-6.7,15-15,15h-312
										c-8.3,0-15-6.7-15-15v-62C1000.1,582.5,1006.8,575.8,1015.1,575.8z"
                          />
                          <g id="Group_25804-4">
                            <g id="Full-time_HR_Team">
                              <text
                                transform="matrix(1 0 0 1 1027.4399 631.75)"
                                class="st5 st6 st7"
                              >
                                FULL-TIME HR TEAM
                              </text>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Component_76_14">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <defs>
                    <rect id="SVGID_13_" y="488" width="342" height="92" />
                  </defs>
                  <clipPath id="SVGID_14_">
                    <use
                      xlinkHref="#SVGID_13_"
                      style={{ overflow: "visible" }}
                    />
                  </clipPath>
                  <g class="st14">
                    <g id="Mask_Group_24-3">
                      <g id="Group_25863-3">
                        <g id="_-_Automatic_10_000_coverage_paid_by_PeerSource_-_Option_to_elect_supplemental_coverage_through_Unum_3">
                          <text
                            transform="matrix(1 0 0 1 46 608)"
                            class="st9 st10 st11"
                          >
                            -
                          </text>
                          <text
                            transform="matrix(1 0 0 1 51.33 608)"
                            class="st9 st10 st11"
                          >
                            {" "}
                          </text>
                          <text
                            transform="matrix(1 0 0 1 54.89 608)"
                            class="st9 st10 st11"
                          >
                            Automatic $10,000 coverage paid
                          </text>
                          <text
                            transform="matrix(1 0 0 1 46 627)"
                            class="st9 st10 st11"
                          >
                            by PeerSource
                          </text>
                          <text
                            transform="matrix(1 0 0 1 46 665)"
                            class="st9 st10 st11"
                          >
                            - Option to elect supplemental
                          </text>
                          <text
                            transform="matrix(1 0 0 1 46 684)"
                            class="st9 st10 st11"
                          >
                            coverage through Unum
                          </text>
                        </g>
                        <g id="Group_25862-3">
                          <path
                            id="Rectangle_300-5"
                            class="st4"
                            d="M14,488h314c7.7,0,14,6.3,14,14v64c0,7.7-6.3,14-14,14H14c-7.7,0-14-6.3-14-14
										v-64C0,494.3,6.3,488,14,488z"
                          />
                          <g id="Group_25804-5">
                            <g id="Life_and_AD_D">
                              <text
                                transform="matrix(1 0 0 1 56 546)"
                                class="st5 st6 st7"
                              >
                                LIFE AND AD&amp;D
                              </text>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Component_75_12">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <defs>
                    <rect
                      id="SVGID_17_"
                      x="939"
                      y="140.5"
                      width="342"
                      height="92"
                    />
                  </defs>
                  <clipPath id="SVGID_18_">
                    <use
                      xlinkHref="#SVGID_17_"
                      style={{ overflow: "visible" }}
                    />
                  </clipPath>
                  <g class="st16">
                    <g id="Mask_Group_22-5">
                      <g id="Group_25861-5">
                        <g id="_2_plans_through_MetLife-5">
                          <text
                            transform="matrix(1 0 0 1 1026 270.5)"
                            class="st9 st10 st11"
                          >
                            2 plans through MetLife
                          </text>
                        </g>
                        <g id="Group_25859-5">
                          <path
                            id="Rectangle_300-9"
                            class="st4"
                            d="M954,140.5h312c8.3,0,15,6.7,15,15v62c0,8.3-6.7,15-15,15H954
										c-8.3,0-15-6.7-15-15v-62C939,147.2,945.7,140.5,954,140.5z"
                          />
                          <g id="Group_25804-9">
                            <g id="Online_Timesheets_Onboarding">
                              <text
                                transform="matrix(1 0 0 1 1053.59 177.5)"
                                class="st5 st6 st7"
                              >
                                DIGITAL
                              </text>
                              <text
                                transform="matrix(1 0 0 1 1023.3099 213.5)"
                                class="st5 st6 st7"
                              >
                                TIMESHEETS
                              </text>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Component_75_12-2">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <defs>
                    <rect
                      id="SVGID_19_"
                      x="1000.1"
                      y="274.2"
                      width="342"
                      height="92"
                    />
                  </defs>
                  <clipPath id="SVGID_20_">
                    <use
                      xlinkHref="#SVGID_19_"
                      style={{ overflow: "visible" }}
                    />
                  </clipPath>
                  <g class="st17">
                    <g id="Mask_Group_22-5-2">
                      <g id="Group_25861-5-2">
                        <g id="_2_plans_through_MetLife-5-2">
                          <text
                            transform="matrix(1 0 0 1 1087.14 404.25)"
                            class="st9 st10 st11"
                          >
                            2 plans through MetLife
                          </text>
                        </g>
                        <g id="Group_25859-5-2">
                          <path
                            id="Rectangle_300-9-2"
                            class="st4"
                            d="M1015.1,274.2h312c8.3,0,15,6.7,15,15v62c0,8.3-6.7,15-15,15h-312
										c-8.3,0-15-6.7-15-15v-62C1000.1,280.9,1006.8,274.2,1015.1,274.2z"
                          />
                          <g id="Group_25804-9-2">
                            <g id="Online_Timesheets_Onboarding-2">
                              <text
                                transform="matrix(1 0 0 1 1115.97 311.25)"
                                class="st5 st6 st7"
                              >
                                ONLINE
                              </text>
                              <text
                                transform="matrix(1 0 0 1 1068.61 347.25)"
                                class="st5 st6 st7"
                              >
                                ONBOARDING
                              </text>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Group_25865">
        <path
          id="Rectangle_299"
          class="st18"
          d="M540,421h317c8.3,0,15,6.7,15,15v52c0,8.3-6.7,15-15,15H540c-8.3,0-15-6.7-15-15v-52
		C525,427.7,531.7,421,540,421z"
        />
        <g id="contractor">
          <text transform="matrix(1 0 0 1 596 474)" class="st5 st6 st7">
            CONTRACTOR
          </text>
        </g>
      </g>
      <g id="Component_76_15">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <defs>
                    <rect
                      id="SVGID_21_"
                      x="1027.9"
                      y="416"
                      width="342"
                      height="92"
                    />
                  </defs>
                  <clipPath id="SVGID_22_">
                    <use
                      xlinkHref="#SVGID_21_"
                      style={{ overflow: "visible" }}
                    />
                  </clipPath>
                  <g class="st19">
                    <g id="Mask_Group_24-4">
                      <g id="Group_25863-4">
                        <g id="_-_Automatic_10_000_coverage_paid_by_PeerSource_-_Option_to_elect_supplemental_coverage_through_Unum_4">
                          <text
                            transform="matrix(1 0 0 1 1073.91 536)"
                            class="st9 st10 st11"
                          >
                            -
                          </text>
                          <text
                            transform="matrix(1 0 0 1 1079.24 536)"
                            class="st9 st10 st11"
                          >
                            {" "}
                          </text>
                          <text
                            transform="matrix(1 0 0 1 1082.8 536)"
                            class="st9 st10 st11"
                          >
                            Automatic $10,000 coverage paid
                          </text>
                          <text
                            transform="matrix(1 0 0 1 1073.91 555)"
                            class="st9 st10 st11"
                          >
                            by PeerSource
                          </text>
                          <text
                            transform="matrix(1 0 0 1 1073.91 593)"
                            class="st9 st10 st11"
                          >
                            - Option to elect supplemental
                          </text>
                          <text
                            transform="matrix(1 0 0 1 1073.91 612)"
                            class="st9 st10 st11"
                          >
                            coverage through Unum
                          </text>
                        </g>
                        <g id="Group_25862-4">
                          <path
                            id="Rectangle_300-6"
                            class="st4"
                            d="M1042.9,416h312c8.3,0,15,6.7,15,15v62c0,8.3-6.7,15-15,15h-312
										c-8.3,0-15-6.7-15-15v-62C1027.9,422.7,1034.6,416,1042.9,416z"
                          />
                          <g id="Group_25804-6">
                            <g id="Direct_Deposit">
                              <text
                                transform="matrix(1 0 0 1 1079.91 474)"
                                class="st5 st6 st7"
                              >
                                DIRECT DEPOSIT
                              </text>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g className="has-dropdown">
        <g className="dropdown">
          <g>
            <rect x="818.5" y="78.5" class="st5" width="341" height="91" />
            <path
              class="st4"
              d="M1159,79v90H819V79H1159 M1160,78H818v92h342V78L1160,78z"
            />
          </g>
          <text
            transform="matrix(1 0 0 1 885.7355 131.6909)"
            class="st20 st21 st22"
          >
            With 4% company match
          </text>
        </g>
        <g className="dropdown-btn">
          <path
            class="st4"
            d="M833,0h312c8.3,0,15,6.7,15,15v62c0,8.3-6.7,15-15,15H833c-8.3,0-15-6.7-15-15V15C818,6.7,824.7,0,833,0z"
          />
          <g id="caret-down_2_">
            <circle class="st23" cx="1040.3" cy="46.4" r="10.7" />
            <polyline
              class="st24"
              points="1044.6,44.2 1040.3,48.6 1036,44.2 			"
            />
          </g>
          <g>
            <text
              transform="matrix(1 0 0 1 927.0344 56.7829)"
              class="st5 st6 st7"
            >
              401(K)
            </text>
          </g>
        </g>
      </g>
      <g className="has-dropdown">
        <g className="dropdown">
          <g>
            <rect x="115.5" y="241.5" class="st5" width="341" height="91" />
            <path
              class="st4"
              d="M456,242v90H116v-90H456 M457,241H115v92h342V241L457,241z"
            />
          </g>
          <text
            transform="matrix(1 0 0 1 166.2274 294.6909)"
            className="st20 st21 st22"
          >
            2 plans through Anthem BCBS
          </text>
        </g>
        <g className="dropdown-btn">
          <path
            className="st4"
            d="M130,163h312c8.3,0,15,6.7,15,15v62c0,8.3-6.7,15-15,15H130c-8.3,0-15-6.7-15-15v-62
			C115,169.7,121.7,163,130,163z"
          />
          <g id="caret-down_00000146496187317690240660000007980589474094718611_">
            <circle class="st23" cx="342" cy="209.4" r="10.7" />
            <polyline
              class="st24"
              points="346.4,207.2 342,211.6 337.7,207.2 			"
            />
          </g>
          <g>
            <text
              transform="matrix(1 0 0 1 207.4559 219.783)"
              class="st5 st6 st7"
            >
              DENTAL
            </text>
          </g>
        </g>
      </g>
      <g className="has-dropdown">
        <g className="dropdown">
          <g>
            <rect x="11.2" y="414.5" className="st9" width="341" height="91" />
            <path
              className="st4"
              d="M351.7,415v90h-340v-90H351.7 M352.7,414h-342v92h342V414L352.7,414z"
            />
          </g>
          <text
            transform="matrix(1 0 0 1 55.9274 467.6909)"
            className="st20 st21 st22"
          >
            2 plans through Anthem BCBS
          </text>
        </g>
        <g className="dropdown-btn">
          <path
            className="st4"
            d="M25.7,336h312c8.3,0,15,6.7,15,15v62c0,8.3-6.7,15-15,15h-312c-8.3,0-15-6.7-15-15v-62
			C10.7,342.7,17.4,336,25.7,336z"
          />
          <g id="caret-down_00000030472506169715284240000001755383155286312112_">
            <circle className="st23" cx="237.7" cy="382.4" r="10.7" />
            <polyline
              className="st24"
              points="242.1,380.2 237.7,384.6 233.4,380.2"
            />
          </g>
          <g>
            <text
              transform="matrix(1 0 0 1 103.1559 392.783)"
              className="st5 st6 st7"
            >
              VISION
            </text>
          </g>
        </g>
      </g>
      <g className="has-dropdown">
        <g className="dropdown">
          <g>
            <rect x="236.5" y="78.5" class="st5" width="341" height="91" />
            <path
              class="st4"
              d="M577,79v90H237V79H577 M578,78H236v92h342V78L578,78z"
            />
          </g>
          <text
            transform="matrix(1 0 0 1 303.4476 131.6909)"
            class="st20 st21 st22"
          >
            6 plans through Anthem BCBS
          </text>
        </g>
        <g className="dropdown-btn">
          <path
            class="st4"
            d="M251,0h312c8.3,0,15,6.7,15,15v62c0,8.3-6.7,15-15,15H251c-8.3,0-15-6.7-15-15V15C236,6.7,242.7,0,251,0z"
          />
          <g id="caret-down">
            <circle class="st23" cx="469" cy="46.4" r="10.7" />
            <polyline class="st24" points="473.3,44.2 469,48.6 464.6,44.2 			" />
          </g>
          <g>
            <text
              transform="matrix(1 0 0 1 334.3734 56.7829)"
              class="st5 st6 st7"
            >
              HEALTH
            </text>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default OncologyBenefitsDiagram
